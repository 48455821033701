<template>
  <div class="d-flex align-items-center">
    <button
      @click="selectCurrent"
      :class="calendarIsBusy ? 'is-busy' : ''"
      class="btn btn-primary me-2"
    >Today</button>

    <IconButton
      @click="selectPrevious"
      :icon="'chevron-left'"
      :class="calendarIsBusy ? 'is-busy' : ''"
      class="btn"
    ></IconButton>

    <IconButton
      @click="selectNext"
      :icon="'chevron-right'"
      :class="calendarIsBusy ? 'is-busy' : ''"
      class="btn"
    ></IconButton>

    <div class="h5 m-0 ms-2">{{ selectedMonth }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapState } from 'vuex';

export default {
  name: 'CalendarDateSelector',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
  },
  props: {
    selectedDate: {
      type: Object,
      required: true,
    },
    currentDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('calendar', ['calendarIsBusy']),
    selectedMonth() {
      return this.selectedDate.format('MMM YYYY');
    },
  },
  methods: {
    selectPrevious() {
      const newSelectedDate = dayjs(this.selectedDate).subtract(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    },
    selectCurrent() {
      const newSelectedDate = dayjs(this.currentDate);
      this.$emit('dateSelected', newSelectedDate);
    },
    selectNext() {
      const newSelectedDate = dayjs(this.selectedDate).add(1, 'month');
      this.$emit('dateSelected', newSelectedDate);
    },
  },
};
</script>
